import React from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import { homePagePaths } from '../translate'
import Header from "../components/header"
import Footer from "../components/footer"
import Title from "../components/title"
import Icon from "../components/icon"
import ContactPage from "../components/pages/contact-page"
import Seo from "../components/seo"


const PageContact = ({data, location, pageContext}) => {

    const post = data?.nodePage
    const form = data.form
    const contactItems = data?.contactItems
    return (
      <Layout
        location={location}
        langcode={post?.langcode}
        translationPaths={homePagePaths}
      >
      <Seo
          title={post?.title}
          lang={post?.langcode}
          description={post?.body?.summary}
          image={post?.relationships?.field_image?.image_style_uri?._653_432}
        />
        <Header
          lang={pageContext?.langcode}
          location={location}
         />
          <ContactPage
            post={post}
            lang={post?.langcode}
            contactItems={contactItems}
            bannerHeight="408px"
            form={form}
           />
        <Footer
          lang={pageContext?.langcode}
        />
      </Layout>
    )

    {/*
    return(
        <div>
            <Layout translationPaths={homePagePaths}>
                <Header/>
                  <div className="color-top-image">
                    <img src={nodePage?.relationships?.field_image?.uri.url} className={nodeContact?.title}/>
                  </div>
                    <Title title={nodeContact?.title} color="bordeaux" type={1} className="size-56-line-64 d-flex justify-content-center title-on-image" />
                    <Title title={nodeContact?.field_map_title} color="bordeaux" type={2} className="size-40-line-48 d-flex justify-content-center title-later-image" />
                    <div className="container content-later-title">
                      <div className="row">
                        <div className="col-10 col-lg4">
                          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2984.4858921008613!2d2.01784611469679!3d41.580370091973464!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a492d3acdf24cb%3A0x9a27e6212f2a1b60!2sIglesia%20Evang%C3%A9lica%20Unida!5e0!3m2!1ses!2ses!4v1670259355113!5m2!1ses!2ses" width="890" height="560" styles="border:0;"></iframe>
                        </div>
                        <div className="col col-lg4">
                          <Icon icon="icons-32-x-32-export-clock" iconClassName="icon space-button" iconSize={26} color="bordeaux" /><Title title={nodeContact?.field_schedule} color="black" type={5} className="size-16-line-20 d-flex light table-spaces-1" />
                          <Icon icon="icons-32-x-32-export-phone-call" iconClassName="icon space-button" iconSize={26} color="bordeaux" /><Title title={nodeContact?.field_phone} color="black" type={5} className="size-16-line-20 d-flex light table-spaces-1 table-spaces-2" />
                          <Icon icon="icons-32-x-32-export-mail" iconClassName="icon space-button" iconSize={26} color="bordeaux" /><Title title={nodeContact?.field_email} color="black" type={5} className="size-16-line-20 d-flex light table-spaces-1 table-spaces-2" />
                          <Icon icon="icons-32-x-32-export-maps" iconClassName="icon space-button" iconSize={26} color="bordeaux" /><Title title={nodeContact?.field_direction} color="black" type={5} className="size-16-line-20 d-flex light table-spaces-1 table-spaces-2" />
                        </div>
                      </div>
                    </div>
                    <p></p>
                    <div className="bg-grey form-style">
                      <ContactForm data={form} />
                    </div>
                <Footer/>
            </Layout>
        </div>
    )
    */}
}




export const pageQuery = graphql`
query PageContact ($drupal_internal__nid: Int, $langcode: String!) {
  nodePage (
    drupal_internal__nid: {eq: $drupal_internal__nid},
    langcode:{eq: $langcode}
    ){
      title
      body {
        value
        summary
      }
      relationships{
        field_image{
          image_style_uri {
            _120x120
            _1440x408
            _294x192
            _541x359
            _653_432
            _1103x488
            large
            medium
            thumbnail
            wide
          }
        }
      }
    }
    contactItems: nodeContact (langcode: {eq: $langcode}) {
      title
      field_email
      field_map_title
      field_phone
      field_schedule
      field_direction
      relationships {
        field_form {
          elements {
            name
            type
            attributes {
              value
              name
            }
          }
        }
      }
    }
    staticMap {
        mapUrl
    }
    form: webformWebform(drupal_internal__id: {eq: "contact_general"}) {
      title
      drupal_internal__id
      langcode
      elements {
        name
        options {
          label
          value
        }
        type
        attributes {
          name
          value
        }
      }
    }
}
`

export default PageContact
