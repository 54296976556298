import React, { useContext, useState } from "react"
import { LanguageContext } from "../../context"
import Banner from "../banner"
import BlogPostItem from "../blog/blog-item"
import BlogList from "../blog/blog-list"
import Button from "../button-visit-us"
import Button2 from "../button-generic"
import { navigate, useStaticQuery, graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import PageTopContent from "../home-top-content"
import { useFlexSearch } from "react-use-flexsearch"
import { useLunr } from "react-lunr"
import { Index } from "lunr"
import Filters from "../search/filters"
import Section from "../section"
import ServiceItem from "../services/service-item"
import Pagination from "../pagination"
import InteriorContactItem from "../interior-contact-item"
import GoogleMap from "../google-maps"
import Webform from "../../../plugins/gatsby-drupal-webform-unida"
import Toast from "react-bootstrap/Toast"
import LeafletMap from "../leaflet-maps"


const ContactPage = (props) => {

  const {
    post,
    bannerHeight="408px",
    nodeContact,
    //localSearchPages,
    buttonBanner,
    lang,
    pageContext,
    pageInfo,
    initialQuery,
    refreshList,
    contactItems,
    form
  } = props

  const { t } = useContext(LanguageContext)
  const breakpoints = useBreakpoint()

  const coords = {
    latitude: 41.5803685,
    longitude: 2.0200456,
    zoom: 11
  }

  return(
    <div className="page-contact">
    <Banner
      post={post}
      height={bannerHeight}
      backgroundColor="green"
    />

    <section className="section-contact p-b-80 small-p-b-40">
      <div className="container p-0 small-p-l-16">
      <h2 className="H2-Medium-C---bordeaux  p-t-80 p-b-55 medium-m-b-74 small-m-b-16 medium-p-b-0 small-p-b-0 m-b-0 small-p-t-25 medium-p-t-56 medium-m-b-42">
        {t("Troba'ns al mapa")}
      </h2>
      <div className="row">
        <div className="col-12 col-sm-12 col-lg-9 p-l-r-10 order-sm-last order-lg-first order-last">
          {/*<GoogleMap /> */}
          {typeof window !== "undefined"
            &&
            <LeafletMap
              coords={coords}
              display_name="Església UNIDA de Terrassa"
            />
          }
        </div>
        <div className={`${breakpoints.sm_md ? 'd-flex justify-content-center' : ''} col-12 col-lg-3 col-sm-12 sidebar`}>
            <InteriorContactItem
              key={1}
              icon="icons-32-x-32-export-clock"
              text={contactItems?.field_schedule}
            />
            <InteriorContactItem
              key={2}
              icon="icons-32-x-32-export-phone-call"
              text={contactItems?.field_phone}
            />
            <InteriorContactItem
              key={3}
              icon="icons-32-x-32-export-mail"
              text={contactItems?.field_email}
            />
            <InteriorContactItem
              key={4}
              icon="icons-32-x-32-export-maps"
              text={contactItems?.field_direction}
            />
        </div>
      </div>
      </div>
    </section>

    <section className="section-contact-form background-beig p-b-80 small-p-b-64">
      <div className="container p-0 small-p-l-16">
        <h2 className="H2-Medium-C---green-dark  p-t-80 p-b-32 medium-m-b-74 small-m-b-16 medium-p-b-0 small-p-b-0 small-p-t-16">
          {t("Envia'ns el teu missatge")}
        </h2>
        <div className="form-container justify-content-center m-w-652 mx-auto">
          <ContactForm
            data={form}
          />
        </div>
      </div>
    </section>
    </div>
  )
}


const ContactForm = ({ data }) => {

  const [showA, setShowA] = useState(false);
  const toggleShowA = () => {
    setShowA(!showA);
  }

  return(
     <React.Fragment>
      <Webform
        webform={data}
        endpoint={`${process.env.GATSBY_DRUPAL_API_URL}/react_webform_backend/submit`} // "http://localhost:8888/react_webform_backend/submit"
        onSuccess={(response) => setShowA(true)}
        className="row"
      />
      <Toast
         show={showA}
         onClose={toggleShowA}
         bg="success"
       >
         <Toast.Header>
           <strong className="me-auto">Missatge enviat</strong>
         </Toast.Header>
         <Toast.Body>Moltes gràcies! El teu missatge s'ha enviat amb èxit!</Toast.Body>
       </Toast>
      </React.Fragment>
    )
  }


 export default ContactPage
