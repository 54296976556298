import React, {useState, useEffect} from "react"
import CategoryItem from "../categories/category-tag-item"

const YOUTUBE_API_KEY = process.env.GATSBY_API_YOUTUBE

const FeaturedItem = (props) => {

  const { featuredItem } = props

  const [thumbnailUrl, setThumbnailUrl] = useState('');

  useEffect(() => {
    fetch(`https://ytimg.googleusercontent.com/vi/${
      featuredItem?.field_url_youtube?.split('/').pop()
    }/sddefault.jpg`,)
      .then(response => response.json())
      .then(data => {
        const items = data?.items && data?.items[0] ? data?.items[0] : []
        const thumbnailUrl = items?.snippet?.thumbnails?.medium?.url;
        setThumbnailUrl(thumbnailUrl);
      })
      .catch(
        (error) => {
          //setIsLoaded(true);
          //setError(error);
          setThumbnailUrl(featuredItem?.relationships?.field_image?.image_style_uri?.wide);
        }
      );
  }, [featuredItem]);

  const getImage = () => {
    let image = featuredItem?.relationships?.field_image?.image_style_uri?.wide
    let youtubeUrl = featuredItem?.field_url_youtube
    if(youtubeUrl){
      return `https://ytimg.googleusercontent.com/vi/${
        youtubeUrl.split('/').pop()
      }/sddefault.jpg`
    }
    return image
  }

  return (
    <div className="featured-item m-b-40">
      <div className="row">
        <div className="col-lg-8 image-wrapper">
        <a href={featuredItem?.field_cta?.uri} alt={featuredItem?.title}>
          <img
            src={thumbnailUrl}
            alt={featuredItem?.title}
           />
        </a>
        </div>
        <div className="col-lg-4 text-wrpper">
            <div className="press-date-published">
              <span className="Small-Bold-L---Bordeaux uppercase m-b-16">{featuredItem?.field_publication_date}</span>
            </div>
            <div className="press-title">{featuredItem?.title}</div>
            <div
              className="press-teaser m-b-16"
              dangerouslySetInnerHTML={{__html: featuredItem?.body?.value}}
              />
            <div className="press-url m-b-27"><a
              href={featuredItem?.field_cta?.uri}
              alt={featuredItem?.title}
              className={`Normal-Bold-L---bordeaux uppercase`}
              target="_blank"
              >
              {featuredItem?.field_cta?.title}
              </a>
              </div>
              <div className="press-category m-b-10">
                <CategoryItem
                  title={featuredItem?.relationships?.field_category[0]?.name}
                  icon="icons-32-x-32-export-tag"
                  color="bordeaux"
                  path={featuredItem?.relationships?.field_category[0]?.alias}
                />
              </div>
              <div className="press-source">
                <small>
                  <em>
                    {featuredItem?.field_publisher}
                  </em>
                </small>
              </div>
        </div>
      </div>
    </div>
  )

}

const YoutubeThumbnail = ({ videoId }) => {
  const [thumbnailUrl, setThumbnailUrl] = useState('');

  useEffect(() => {
    fetch(`https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${YOUTUBE_API_KEY}&part=snippet`)
      .then(response => response.json())
      .then(data => {
        const items = data?.items && data?.items[0] ? data?.items[0] : []
        const thumbnailUrl = items?.snippet?.thumbnails?.medium?.url;
        setThumbnailUrl(thumbnailUrl);
      });
  }, [videoId]);

  return (
    <img src={thumbnailUrl} alt="YouTube Thumbnail" />
  );
}

export default FeaturedItem
